import React from "react";

import tracking from "../../resources/photos/tracking.png"
import seeding from "../../resources/photos/seeding.png"




export default function Who () {
    return(
        <div className="pageBorder">
            <p className="mainTextHead">
                Company Profile
            </p>
            <p className="mainTextBody">
                Over the last two centuries, humans have released into the Earth's environment vast quantities of pollutants, from the well known CO<sub>2</sub>, to lesser known and more harmful gases and elements.
                <br />
                <br />
                This same environment is where our food is grown and these harmful pollutants have found their way into our food chains through air, water and soil.
                <br />
                <br />
                In 2022, Arkanic was founded with the goal to produce pure food, grown naturally, free from these contaminants.
                <br />
                <br />
                We are developing and deploying cutting edge, state of the art technology to allow us to produce clean food, efficiently and sustainably.
                <br />
                <br />

            </p>

            <img src={seeding} className="p-[4%]"/>
            
            <div className="pageBorderDiv"></div>
            
            <p className="mainTextHead">
                Vision
            </p>
            <p className="mainTextBody">
                At Arkanic, we aim to produce the highest quality food using minimum resources at the lowest cost.
                <br />
                <br />
                We see a future where agriculture needs to survive in an unstable climate and a polluted environment.
                <br />
                <br />
                With increasingly common droughts and floods, heatwaves and extreme weather events, loss of food production is inevitable.
                <br />
                <br />
                Any crops that do survive the harsh climate are laced with pollutants that have entered our food chains.
                <br />
                <br />
                Arkanic envisions a future where we can provide everyone with pure food at an affordable price without causing further damage to our planet.
                <br />
                <br />
                We aim to bring this future to life today.
            </p>

            <img src={tracking} className="p-[4%]"/>

        </div>
    )
}

