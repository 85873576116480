import React from "react";

import purple from "../../resources/photos/purple.png"
import packing from "../../resources/photos/packing.png"





export default function What () {
    return(
        <div className="pageBorder">
            <p className="mainTextHead">
                Clean Food
            </p>
            <p className="mainTextBody">
                At Arkanic we are growing pure, natural food, isolated from all the pollutants and contaminants that have been released into the environment with industrialisation.
                <br />
                <br />
                We do not use any pesticides or insecticides, and filter everything that our plants are exposed to, from the soil to the water to the air, preventing any pollution from entering our food chain.
                <br />
                <br />
                Unlike currently available market products, our food is free from highly toxic heavy metals, bad bacteria, and unnatural chemicals.
                <br />
                <br />
                Our food is healthier, more nutritious, and filled with more flavour.
                <br />
                <br />
                And we do all this sustainably, minimizing the use of natural resources and preserving our precious environment.
                <br />
                <br />
            </p>

            <img src={packing} className="p-[4%]"/>
            
            <div className="pageBorderDiv">
            </div>
            <p className="mainTextHead">
                Pioneering Technology
            </p>
            <p className="mainTextBody">
                Arkanic are developing world-class technology where food is grown in controlled conditions.
                <br />
                <br />
                At our production facility, all criteria are measured and precisely controlled.
                <br />
                <br />
                The air in our unit is filtered every few minutes to remove any dust and pollution, ensuring a clean atmosphere for our plants to breathe in.
                <br />
                <br />
                We use our own RO water, refined to the highest quality, fit for human consumption.
                <br />
                <br />
                The soil that the plants are grown in has been purified and carefully balanced with a mix of nutrients to ensure healthy, nutritious and safe crops.
                <br />
                <br />
                Other parameters such as temperature, humidity and pH are also computer controlled as required to provide each plant the perfect environment.
                <br />
                <br />
                {/* // TODO:
                Add: ISO and BSL
                Our facility has the highest sanitation and hygene standards.
                <br />
                <br />
                */}
            </p>

            <img src={purple} className="p-[4%]"/>

        </div>
    )
}

