import React, { useEffect, useRef } from "react";
import useWindowSize from "./useWindowSize";

import lettuce1 from '../resources/lettuce/Lettuce1.jpg'


export default function Canvas (props:any) {
    
    const ref = useRef();
    const randcanv = useRef();
    const perlcanv = useRef();

    const {height, width} = useWindowSize()

    function randomNoise(canvas:any) {
        var g = canvas.getContext("2d");
        var imageData = g.getImageData(0, 0, width, height)
        var pixels = imageData.data
        var n = pixels.length
        var i = 0;
        var r;

        var d = Math.random()*50 + 50

        while (i < n) {

            r = Math.random()

            // pixels[i++] = 100;
            
            pixels[i++] = r*128 + d;
            pixels[i++] = Math.random()*100 + 92 + d;
            pixels[i++] = r*128 + d + Math.random()*64;

            // pixels[i++] = Math.random() * 64 + 64;
            // pixels[i++] = r * 64 + 192;
            // pixels[i++] = (1-r) * 192 + 64 ;

            pixels[i++] = 255;
        }
        g.putImageData(imageData, 0, 0);
        return canvas;
    }

    function perlinNoise(perl: any, noise:any) {

        var noise = randomNoise(noise);
        var g = perl.getContext("2d");

        var lim = Math.max(width, height)
        
        for (var size = 4; size <= lim; size *= 2) {
            var x = Math.random() * (width - size);
            var y = Math.random() * (height - size);
            g.globalAlpha = 4 / (size);
            g.drawImage(noise, x, y, size, size, 0, 0, lim, lim);
        }
    
        return perl;
    }

    function draw(perl:any, canvas:any, timesince:number) {

        var g = canvas.getContext("2d");

        g.globalAlpha = 0.01 + timesince/100000
        // console.log(timesince/100000);
        
        g.drawImage(perl, 0, 0, width, height, 0, 0, width, height)
        
        return canvas
    }



    useEffect(()=>{
        const canvas:any = ref.current;
        const noise:any = randcanv.current;
        const perl:any = perlcanv.current;

        // new canvas, arc draw trace out arkanic logo?
        // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        // ctx.fillStyle = '#000000'
        // ctx.beginPath()
        // ctx.arc(50, 100, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
        // ctx.fill()

        let animationID:number
         
        var time = new Date().getTime();
        var timesince;

        perlinNoise(perl, noise)

        const render = () => {

            timesince = new Date().getTime() - time
            
            if (timesince > 3000){

                // console.log(timesince);

                perlinNoise(perl, noise)
                time = new Date().getTime()
                
            }
            
            draw(perl, canvas, timesince)

            animationID = window.requestAnimationFrame(render)

        }

        // Tag this out for testing img
        
        // render()

        return () => window.cancelAnimationFrame(animationID)

    },[width, height])

    return (<>
        <canvas
            className="
            bg-arkanic-teal
            absolute -z-10
            "
            ref={ref} width={width} height={height} {...props}
        />
        {/* <img
            className="absolute w-[100%] h-[10000%] z-[-5] object-cover overflow-clip"
            src={lettuce1}
        /> */}
        <canvas
            className="
            absolute hidden
            "
            ref={randcanv} width={width} height={height} {...props}
        />
        <canvas
            className="
            absolute hidden
            "
            ref={perlcanv} width={width} height={height} {...props}
        />
    </>)
}

