import React from "react";

import purple from "../resources/photos/purple.png"
import packing from "../resources/photos/packing.png"

import tracking from "../resources/photos/tracking.png"
import seeding from "../resources/photos/seeding.png"

import building from "../resources/photos/building.png"
import watertray from "../resources/photos/watertray.png"
import airwall from "../resources/photos/airwall.png"
import nurserytrays from "../resources/photos/nurserytrays.png"
import fridge from "../resources/photos/fridge.png"




export default function Videos () {
    return(
        <div className="h-full overflow-y-auto scrollbar">
            <div className="pageBorder">
                <p className="mainTextHead">
                    Videos
                </p>

                <p className="textVideoTitle">
                    Arkanic Introduction
                </p>
                
                <iframe
                    className="
                        w-full px-[4%]
                        aspect-video
                    "
                    src="https://www.youtube.com/embed/zyvRy5N06qo?si=WiqwfLSc-hGRu5DF"
                    title="Arkanic Introduction"
                    allowFullScreen
                />

                <p className="textVideoTitle">
                    Where does your food come from?
                </p>

                <iframe
                    className="
                        w-full px-[4%]
                        aspect-video
                    "
                    src="https://www.youtube.com/embed/WXb7hHiWkSw?si=hk4yhnV1elkcVQ2P"
                    title="Where does your food come from?"
                    allowFullScreen
                />


                
                <div className="pageBorderDiv">
                </div>

                <p className="mainTextHead">
                    Images
                </p>

                <img src={airwall} className="p-[4%]"/>
                <img src={tracking} className="p-[4%]"/>
                <img src={watertray} className="p-[4%]"/>
                <img src={seeding} className="p-[4%]"/>
                <img src={purple} className="p-[4%]"/>
                <img src={building} className="p-[4%]"/>
                <img src={nurserytrays} className="p-[4%]"/>
                <img src={fridge} className="p-[4%]"/>
                <img src={packing} className="p-[4%]"/>

            </div>
        </div>
    )
}

