import React from "react";

import building from "../../resources/photos/building.png"
import watertray from "../../resources/photos/watertray.png"
import airwall from "../../resources/photos/airwall.png"
import nurserytrays from "../../resources/photos/nurserytrays.png"
import fridge from "../../resources/photos/fridge.png"



export default function Why () {
    return(
        <>
            <div className="pageBorder">
                <p className="mainTextHead">
                    Crisis
                </p>
                <p className="mainTextBody">
                    Since the industrial revolution, the environment has been continuously polluted with harmful and toxic substances.
                    <br />
                    <br />
                    It has now reached levels that affect our daily lives:
                    it is present in the air we breathe, the water we drink, and the food we eat.
                    <br />
                    <br />
                    All our food chains have been contaminated, as all our produce grows in this deteriorating environment.
                    <br />
                    <br />
                    The environment is so polluted that the planet's climate is changing. We see an increasing number of floods and droughts, fresh water shortages, heatwaves and other extreme weather events.
                    <br />
                    <br />
                    As these changes are straining our food supply, demand is continuously growing with an ever-increasing population that needs to be fed.
                    <br />
                    <br />
                </p>

                <img src={fridge} className="p-[4%]"/>
                            
                <p className="mainTextHead">
                    The Path Forward
                </p>
                <p className="mainTextBody">
                    There is an urgent need for a crop system which is shielded from the tarnished environment and dangerous weather while also consuming minimal resources.
                    <br />
                    <br />
                    At Arkanic, we are developing scalable, low cost, state of the art agriculture to produce high quality food in a sustainable manner.
                    <br />
                    <br />
                    Contained in a sealed ecosystem, our farms are shielded from weather conditions, filtered of all pollutants, and can grow 500 times more produce than a traditional farm of the same size.
                    <br />
                    <br />
                </p>

                <img src={nurserytrays} className="p-[4%]"/>
                
                <div className="pageBorderDiv">
                </div>
                <p className="mainTextHead">
                    Land
                </p>
                <p className="mainTextBody">

                    With a population of over 1.5 billion people in India, land availability is stretched to the limit, as over 60% of our land is already used for agriculture.
                    <br />
                    <br />
                    Humans have converted forests into farms, damaging the environment and causing critical biodiversity loss.
                    <br />
                    <br />
                    With overfarming of the same land year after year, the soil is being stripped of the required nutrients, detrimentally affecting the quality of crops.
                    <br />
                    <br />
                    Our technology allows us to grow plants vertically, indoors. We are growing hundreds of acres worth of food in less than 1% of the land.
                    <br />
                    <br />
                </p>

                <img src={building} className="p-[4%]"/>
                
                <p className="mainTextHead">
                    Water
                </p>
                <p className="mainTextBody">
                    All this agriculture has been allowed to consume water at will.
                    <br />
                    <br />
                    In India, over 80% of freshwater is used for agriculture.
                    <br />
                    <br />
                    There is an impending water crisis across the globe, with water table levels and reservoirs being depleted.
                    <br />
                    <br />
                    Agriculture can no longer be allowed to consume unlimited quantities of this precious resource.
                    <br />
                    <br />
                    Our technology allows us to produce crops using less than 1% of the water compared to traditional farming.
                    <br />
                    <br />
                </p>

                <img src={watertray} className="p-[4%]"/>
                
                <p className="mainTextHead">
                    Air
                </p>
                <p className="mainTextBody">
                    With rapid industrialization over the last century, air quality has deteriorated significantly.
                    <br />
                    <br />
                    Air quality index rarely reaches safe levels.
                    <br />
                    <br />
                    There is no doubt that crops grown in polluted air are absorbing and recycling the pollutants into our food chain.
                    <br />
                    <br />
                    Our technology allows our crops to be grown in a pollution free environment.
                    <br />
                    <br />
                </p>
                
                <img src={airwall} className="p-[4%]"/>

            </div>
        </>
    )
}

