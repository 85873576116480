import React from "react";





export default function Contact () {
    return(
        <div className="h-full overflow-y-auto scrollbar">
            <div className="pageBorder">
                <p className="mainTextHead">
                    Contact Us
                </p>
                <p className="mainTextBody">

                    <p className="font-semibold">
                        For large orders or subscriptions, please contact us directly
                    </p>
                    <br />
                    <br />

                    <p className="font-semibold">
                        Phone or WhatsApp
                    </p>
                    <a href="tel:+916289112233" target="_blank"
                    className="underline"
                    >
                        +91 6289112233
                    </a>
                    <br />
                    <br />

                    <p className="font-semibold">
                        Email
                    </p>
                    <a href="mailto:contact@arkanic.com" target="_blank"
                    className="underline"
                    >
                        contact@arkanic.com
                    </a>
                    <br />
                    <br />
                    
                    <p className="font-semibold">
                        Instagram
                    </p>
                    <a href="https://www.instagram.com/eatarkanic/" target="_blank"
                    className="underline"
                    >
                        @eatarkanic
                    </a>
                    <br />
                    <br />

                    <p className="font-semibold">
                        Registered Office
                    </p>
                    Arkanic Private Limited
                    <br />
                    5<sup>th</sup> Floor
                    <br />
                    {/* 9B Wood Street */}
                    9B Dr Martin Luther King Sarani
                    <br />
                    Kolkata 700016
                    <br />
                    West Bengal
                    <br />
                    India
                    <br />
                    <br />
                    

                    <br />
                    <br />
                    <p className="font-semibold">
                        Also available at
                    </p>
                    <br/>
                    Spencer's Quest Mall
                    <br/>
                    Spencer's South City Mall
                </p>
                
                {/* <div className="pageBorderDiv">
                </div>
                <p className="mainTextHead">
                    Feedback
                </p>
                <p className="mainTextBody">
                    Add Feedback Form
                </p> */}

            </div>
        </div>
    )
}

