import React from "react";
import { NavLink, useNavigate } from "react-router-dom";





export default function Error () {

    const navigate = useNavigate()

    return(
        <div className="
        error
        w-full h-full
        flex flex-col justify-evenly items-center
        text-9xl font-semibold
        mob:text-7xl
        ">
            Error
            <div className="
            grid grid-cols-2 grid-rows-1
            mob:grid-cols-1 mob:grid-rows-2 mob:gap-[100%]
            w-full justify-evenly text-center
            ">
                <button className={"errorLink"} onClick={()=>navigate(-1)}>Back</button>
                
                <NavLink className={"errorLink"} to={'/'}>Home</NavLink>
            </div>
        </div>
    )
}

