import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../resources/alogo.png";
import qr from "../resources/splash/arkqr.png"
import shop from "../resources/splash/shopicon.png"
import vid from "../resources/splash/vidicon.png"



export default function Home () {
    return(
        <div className="
        
        h-full w-full
        grid grid-cols-2 grid-rows-2 items-center justify-items-center
        p-16 mob:p-2
        ">
            {/* flex flex-row justify-evenly items-center
            mob:flex-col */}
            {/* text-9xl font-semibold text-slate-400 */}
            <div className="
            homeIcon
            ">
                {/* w-full h-full */}
                <NavLink to={'track'} className="">
                    <img src={qr} className="
                    object-contain
                    "/>
                    <div className="
                    homeText clickable
                    ">
                        Track
                    </div>
                </NavLink>
            </div>
            
            <div className="
            homeIcon
            ">
                {/* w-full h-full */}
                <NavLink to={'story'} className="">
                    <img src={logo} className="
                    object-contain
                    "/>
                    <div className="
                    homeText clickable
                    ">
                        Story
                    </div>
                </NavLink>
            </div>

            <div className="
            homeIcon
            ">
                {/* w-full h-full */}
                <NavLink to={'videos'} className="">
                    <img src={vid} className="
                    object-contain
                    "/>
                    <div className="
                    homeText clickable
                    ">
                        Videos
                    </div>
                </NavLink>
            </div>
            
            <div className="
            homeIcon
            ">
                {/* w-full h-full */}
                <NavLink to={'shop'} className="">
                    <img src={shop} className="
                    object-contain
                    "/>
                    <div className="
                    homeText clickable
                    ">
                        Shop
                    </div>
                </NavLink>
            </div>




        </div>
    )
}

