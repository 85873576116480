import React from "react";




export default function Shop () {

    React.useEffect(()=>{
        window.location.replace('https://shop.arkanic.com')
    },[])

    return(
        <div className="h-full overflow-y-auto scrollbar">
            <div className="pageBorder">

                <p className="mainTextHead">
                    Redirecting to Shop
                </p>

                <p className="mainTextBody">
                    <a
                        href="https://shop.arkanic.com"
                        className="underline"
                    >
                        Click here to go to https://shop.arkanic.com
                    </a>
                    <br/>
                    <br/>
                    If you are not automatically redirected
                </p>

            </div>
        </div>
    )
}

