import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutSubNavbar from "../components/AboutSubNavbar";
import What from "./About/What";
import About from "./About/About";
import Who from "./About/Who";
import Why from "./About/Why";
import Error from "./Error";
import Contact from "./Contact";
import Scroll from "../utilities/Scroll";
import ErrorRedirect from "./Redirect/ErrorRedirect";



export default function AboutRouter () {
    return(
        <div className="
        h-full w-full flex flex-col
        mob:flex-col-reverse
        ">
            <Scroll />
            <Routes>
                <Route index element={<About />}/>
                <Route path="/" element={<AboutSubNavbar />}>
                    <Route path='who' element={<Who />}/>
                    <Route path='what' element={<What />}/>
                    <Route path='why' element={<Why />}/>
                </Route>
            <Route path='*' element={<ErrorRedirect />}/>
            </Routes>
        </div>
    )
}


